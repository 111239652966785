import catalogService from '@/services/catalog.service';
import companyService from '@/services/company.service';
import CatalogService from '@/services/catalog.service';

export const catalog = {
  namespaced: true,
  state: {
    catalog: {},
    catalogs: {
      links: {},
      meta: {},
      results: [],
    },
    queryString: undefined,
    url: undefined,
    newCatalog: null,
    subcatalogs: [],
    subCatalogsCompany: [],
    productsList: [],
    company: null,
    companyList: {
      links: {},
      meta: {},
      results: [],
    },
  },
  mutations: {
    FILL_CATALOGS(state, catalogs) {
      state.catalogs = catalogs;
    },
    FILL_CATALOG(state, catalog) {
      state.catalog = catalog;
    },
    FILL_SUBCATALOGS(state, subcatalogs) {
      state.subcatalogs = subcatalogs;
    },
    FILL_SUBCATALOGS_COMPANY(state, subcatalogs) {
      state.subcatalogs = subcatalogs;
    },
    FILL_NEW_CATALOG(state, newCatalog) {
      state.newCatalog = newCatalog;
    },
    FILL_PRODUCTS_LIST(state, productsList) {
      state.productsList = productsList;
    },
    REMOVE_CATALOG(state, catalog) {
      state.catalog = state.catalog.filter((item) => {
        return item.cod_catalog_prod !== catalog.cod_catalog_prod;
      });
    },
    REMOVE_SUBCATALOG(state, subcatalogo) {
      state.subcatalogo = state.subcatalogo.filter((item) => {
        return item.cod_subcatalogo_prod !== subcatalogo.cod_subcatalogo_prod;
      });
    },
    FILL_COMPANY(state, company) {
      state.company = company;
    },
    FILL_COMPANY_LIST(state, companyList) {
      state.companyList = companyList;
    },
  },
  actions: {
    async getCatalogs({ commit }) {
      const catalogs = await catalogService.getCatalogs();
      catalogs.code === 200 ? commit('FILL_CATALOGS', catalogs.data) : null;
    },
    async getCatalog({ commit }, id) {
      const catalog = await catalogService.getCatalogById(id);
      catalog.code === 200 ? commit('FILL_CATALOG', catalog.data) : null;
    },
    async createCatalog({ commit }, payload) {
      return catalogService
        .createCatalog(payload)
        .then(() => {
          Toast.fire('Catálogo cadastrado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `Erro ao cadastrar novo produto! Por favor, tente novamente.<br><br>
            <ul>
              <li style="font-size: 12px">- ${error.response.data.data.errors.nome_catalog_prod}</li>
              <li style="font-size: 12px">- ${error.response.data.data.errors.desc_catalog_prod}</li>
            </ul>`,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false,
          });
          throw error.response.data;
        });
    },
    async deleteCatalog({ commit }, id) {
      return catalogService.deleteCatalog(id).then(() => {
        Toast.fire('Catálogo removido com sucesso!', '', 'success');
      });
    },
    async createSubCatalog({ commit }, id) {
      return catalogService
        .createSubCatalog(id)
        .then(() => {
          Toast.fire('Subcatálogo cadastrado com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire(
            'Atenção',
            `Erro ao cadastrar novo subcatálogo! Por favor, tente novamente.<br>
            ${error.response.data.error}`,
            'error'
          );
          throw error.response.data;
        });
    },
    async createProductSubCatalog({ commit }, payload) {
      return catalogService
        .createProductSubCatalog(payload)
        .then(() => {
          Toast.fire('Produto adicionado ao catálogo com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire(
            'Atenção',
            `Erro ao adicionar produto ao catálogo! Por favor, tente novamente.<br>
            ${error.response.data.error}`,
            'error'
          );
          throw error.response.data;
        });
    },
    async uploadCatalogCover({ commit }, payload) {
      return CatalogService.uploadImage(payload);
    },
    async uploadSubCatalogCover({ commit }, payload) {
      return CatalogService.uploadSubCatalogImage(payload);
    },
    async getSubCatalogs({ commit }, id) {
      const subcatalogs = await catalogService.getSubCatalogs(id);
      subcatalogs.code === 200 ? commit('FILL_SUBCATALOGS', subcatalogs.data) : null;
    },
    async getSubCatalogsCompany({ commit }, { catalog, companyId }) {
      const subCatalogsCompany = await catalogService.getSubCatalogsCompany(catalog, companyId);
      subCatalogsCompany.code === 200
        ? commit('FILL_SUBCATALOGS_COMPANY', subCatalogsCompany.data)
        : null;
    },
    async deleteSubCatalog({ commit }, id) {
      return catalogService.deleteSubCatalog(id).then(() => {
        Toast.fire('Subcatálogo removido com sucesso!', '', 'success');
      });
    },
    async deleteSubCatalogProduct({ commit }, id) {
      return catalogService.deleteSubCatalogProduct(id);
    },
    async restoreSubCatalogProduct({ commit }, id) {
      return catalogService.restoreSubCatalogProduct(id);
    },
    async getProductBySubcatalogId({ commit }, subcatalogoId) {
      let url = `/subcatalogos/${subcatalogoId}/produtos?` + this.state.queryString;
      const product = await catalogService.getProductBySubCatalogId(url);
      product.code === 200 ? commit('FILL_PRODUCTS_LIST', product.data) : null;
    },
    async subCatalogOrder(payload) {
      const response = payload.state.subcatalogs.results.map((res, index) => {
        return {
          cod_subcatalog_prod: res.cod_subcatalog_prod,
          idx_subcatalogo: index + 1,
        };
      });
      return await catalogService.saveSubCatalogOrder(response).then(() => {
        Toast.fire('Subcatálogo ordenado com sucesso!', '', 'success');
      });
    },
    async getCompany({ commit }) {
      const companyList = await companyService.listAll();
      companyList.code === 200 ? commit('FILL_COMPANY_LIST', companyList.data) : null;
    },
    async productOrder(payload) {
      const response = payload.state.productsList.results.map((res, index) => {
        return {
          id: res.id,
          idx_subcatalog_prod_index: index + 1,
        };
      });
      return await catalogService.saveProductOrder(response).then(() => {
        Toast.fire('Produtos ordenados com sucesso!', '', 'success');
      });
    },
    async getCatalogCompanyId ({commit} , companyId) {
      const response = await catalogService.getCatalogCompanyId(companyId);
      response.code === 200 ? commit('FILL_CATALOGS', response.data) : null;
    }
  },
  getters: {
    companyList: (state) => state.companyList,
    catalogs: (state) => state.catalogs,
    subcatalogs: (state) => state.subcatalogs,
  },
};
