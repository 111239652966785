import { authAxios, userAxios } from '@/plugins/axios';

class AuthService {
  async login(logged) {
    const response = await authAxios.post('/login', {
      email: logged.email,
      password: logged.password
    });
    return response.data;
  }

  async me(logged) {
    const response = await userAxios.get(`/me`, {
      timeout: 1000 * 30,
      headers: {
        Authorization: `Bearer ${logged['access_token']}`
      }
    });
    return response.data;
  }

  async resetPassword(payload) {
    const response = await authAxios.post('/reset-password', payload);
    return response.data;
  }

  async resetPasswordPDV(payload) {
    const response = await authAxios.post('/reset-password-pdv', payload);
    return response.data;
  }

  async requestPasswordToken(email) {
    const response = await authAxios.post('/reset-password-request', {
      email: email
    });
    return response.data;
  }

  async confirmPassword(password) {
    let logged = JSON.parse(sessionStorage.getItem('userData'));
    const response = await authAxios.post('/login?referer=lake', {
      email: logged.email,
      password: password
    });
    return response.data;
  }

  logout() {
    sessionStorage.clear();
  }
}

export default new AuthService();
