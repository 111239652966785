import listService from '@/services/list.service';

export const listModule = {
  namespaced: true,
  state: {
    list: {
      links: {},
      meta: {},
      results: []
    },
    counts: {
      listas_count: 0,
      convidados_count: 0,
    },
    listAll: {
      links: {},
      meta: {},
      results: []
    },
    listAlls: {
      links: {},
      meta: {},
      results: []
    }
  },
  mutations: {
    fill_list(state, list) {
      state.list = list;
    },
    fill_list_count(state, counts) {
      state.counts = counts;
    },
    fill_list_all(state, listAll) {
      state.listAll = listAll;
    },
    fill_list_alls(state, listAlls) {
      state.listAlls = listAlls;
    }
  },
  actions: {
    async counts({ commit }) {
      const response = await listService.listCount();
      if (response.code === 200) {
        commit('fill_list_count', response.data.results);
      }
    },
    async list({ commit }, query) {
      const response = await listService.list(query);
      if (response.code === 200) {
        commit('fill_list', response.data);
      }
    },
    async listAll({ commit }, query) {
      const response = await listService.listAll(query);
      if (response.code === 200) {
        commit('fill_list_all', response.data);
      }
    },
    async listAlls({ commit }, query) {
      const response = await listService.listAlls(query);
      if (response.code === 200) {
        commit('fill_list_alls', response.data);
      }
    },
    async uploadFile({ commit }, payload) {
      const response = await listService.uploadFile(payload);
      return response.data;
    },
    async update({ commit }, payload) {
      return await listService.updateList(payload.id, payload);
    },
    async updateListAll({ commit }, payload) {
      return await listService.updateListAll(payload);
    },
    async createListAll({ commit }, payload) {
      return await listService.createListAll(payload);
    },
    async deleteListAll({ commit }, id) {
      return await listService.deleteListAll(id);
    },
    async create({ commit }, payload) {
      return await listService.createList(payload);
    },
  },
  getters: {
    list: (state) => state.list,
    listAll: (state) => state.listAll,
    listAlls: (state) => state.listAlls,
    counts: (state) => state.counts
  }
};
