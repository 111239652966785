import { mainAxios } from '@/plugins/axios';

class listService {
  // gerenciar lista
  async listCount() {
    const response = await mainAxios.get('/listas-count');
    return response.data;
  }
  async list(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async view(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async createList(payload) {
    const response = await mainAxios.post('/lista', payload);
    return response.data;
  }
  async updateList(id, payload) {
    const response = await mainAxios.put(`/lista/${id}`, payload);
    return response.data;
  }
  async updateListAll(id, payload, config = null) {
    if (config) {
      const response = await mainAxios.put(`/lista-entrada/${id}`, payload, config);
      return response.data;
    }

    const response = await mainAxios.put(`/lista-entrada/${id}`, payload);
    return response.data;
  }
  async deleteList(id) {
    const response = await mainAxios.delete(`/lista/${id}`);
    return response.data;
  }
  // lista-entrada
  async listAll(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async createListAll(payload, config) {
    const response = await mainAxios.post('/lista-entrada', payload, config);
    return response.data;
  }
  async deleteListAll(id) {
    const response = await mainAxios.delete(`/lista-entrada/${id}`);
    return response.data;
  }

  // lista
  async listAlls(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async viewAlls(id) {
    const response = await mainAxios.get('/lista-entrada/view/' + id);
    return response.data;
  }
  async uploadFile(payload, config) {
    const response = await mainAxios.put(`/lista-entrada-upload/${payload.cod_lista_entrada}`, payload, config);
    return response.data;
  }
  async removeListInvite(id) {
    const response = await mainAxios.delete(`/lista-entrada/${id}`);
    return response.data;
  }
}

export default new listService();
