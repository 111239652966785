import VueRouter from 'vue-router'
import Vue from 'vue';


const routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/pages/auth/LoginPage.vue'
      ),
    meta: {
      authRequired: false,
      hideNavbar: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    query: {
      email: String,
      token: String,
      codigo_grupo: String,
    },
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '@/views/pages/auth/ResetPassword.vue'
        ),
    meta: {
      hideNavbar: true,
      authRequired: false
    }
  },
  {
    path: '/request-rest-password-token',
    name: 'request-rest-password-token',
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '@/views/pages/auth/ResetPasswordToken.vue'
        ),
    meta: {
      hideNavbar: true,
      authRequired: false
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '@/views/pages/auth/ChangePassword.vue'
      ),
    meta: {
      hideNavbar: true,
      authRequired: true
    }
  },
  {
    path: '/table-environment/:company?',
    name: 'table-environment',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/configurations/table/TableEnvironment.vue'
        )
  },
  {
    path: '/table-environment-details/:company/:id/:title',
    name: 'table-environment-details',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/configurations/table/Tables.vue'
        )
  },
  {
    path: '/home',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home-page" */ '@/views/pages/HomePage.vue'),
    meta: {
      authRequired: true,
      props: true,
    }
  },

  // DASHBOARD
  {
    path: '/acquisition',
    name: 'acquisition',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/Acquisition.vue'
        )
  },
  {
    path: '/sales-products',
    name: 'sales-products',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/SellProductObservation.vue'
        )
  },
  {
    path: '/contingency',
    name: 'contingency',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/Contingency.vue'
        )
  },
  {
    path: '/faturamento',
    name: 'faturamento',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/Invoicing.vue'
      )
  },
  {
    path: '/fidelity',
    name: 'fidelity',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/Fidelity.vue'
        )
  },
  {
    path: '/mailing',
    name: 'mailing',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/Mailing.vue'
        )
  },
  {
    path: '/sales-analytical',
    name: 'sales-analytical',
    component: () =>
      import(
        /* webpackChunkName: "invoicing" */ '@/views/pages/dashboards/AnalyticalSales.vue'
        )
  },
  {
    path: '/venda-produto',
    name: 'venda-produto',
    component: () =>
      import(
        /* webpackChunkName: "sellProduct" */ '@/views/pages/dashboards/SellProduct.vue'
      )
  },
  {
    path: '/venda-servico',
    name: 'venda-servico',
    component: () =>
      import(
        /* webpackChunkName: "sellService" */ '@/views/pages/dashboards/SellService.vue'
      )
  },
  {
    path: '/operacional',
    name: 'operacional',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/dashboards/Operational.vue'
      )
  },
  {
    path: '/livro-fiscal-icms',
    name: 'livro-fiscal-icms',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/dashboards/LivroFiscalIcms.vue'
        )
  },
  {
    path: '/livro-fiscal-iss',
    name: 'livro-fiscal-iss',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/dashboards/LivroFiscalIss.vue'
        )
  },
  {
    path: '/acompanhamento-casa',
    name: 'acompanhamento-casa',
    component: () =>
      import(
        /* webpackChunkName: "operational" */ '@/views/pages/dashboards/AcompanhamentoCasa.vue'
        )
  },
  {
    path: '/faturamento-canal',
    name: 'faturamento-canal',
    component: () =>
      import(
        /* webpackChunkName: "billingPerChannel" */ '@/views/pages/dashboards/BillingPerChannel.vue'
      )
  },
  {
    path: '/faturamento-por-dia',
    name: 'faturamento-por-dia',
    component: () =>
      import(
        /* webpackChunkName: "billingPerChannel" */ '@/views/pages/dashboards/BillingPerDay.vue'
        )
  },
  {
    path: '/estornos',
    name: 'estornos',
    component: () =>
      import(
        /* webpackChunkName: "chargebacks" */ '@/views/pages/dashboards/Chargebacks.vue'
      )
  },
  {
    path: '/descontos',
    name: 'descontos',
    component: () =>
      import(
        /* webpackChunkName: "discounts" */ '@/views/pages/dashboards/Discounts.vue'
      )
  },
  {
    path: '/fechamento-caixa',
    name: 'fechamento-caixa',
    component: () =>
      import(
        /* webpackChunkName: "boxClosing" */ '@/views/pages/dashboards/BoxClosing.vue'
      )
  },
  {
    path: '/vendas-funcionario',
    name: 'vendas-funcionario',
    component: () =>
      import(
        /* webpackChunkName: "salesPerEmployee" */ '@/views/pages/dashboards/SalesPerEmployee.vue'
      )
  },
  {
    path: '/vendas-delivery',
    name: 'vendas-delivery',
    component: () =>
      import(
        /* webpackChunkName: "salesDelivery" */ '@/views/pages/dashboards/SalesDelivery.vue'
      )
  },

  // GESTÃO DE PRODUTOS (products)
  {
    path: '/products',
    name: 'products',
    query: {
      page: 1,
      per_page: 15,
      order: 'asc',
      order_field: 'cod_prod',
      search: ''
    },
    component: () =>
      import(
        /* webpackChunkName: "product-page" */ '@/views/pages/products/ProductPage.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-product',
    name: 'new-product',
    component: () =>
      import(
        /* webpackChunkName: "new-product" */ '@/views/pages/products/new/main.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/edit-product/:productId(\\d+)',
    name: 'edit-product',
    component: () =>
      import(
        /* webpackChunkName: "edit-product" */ '@/views/pages/products/new/main.vue'
      ),
    props: true,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/product-observation',
    name: 'product-observation',
    component: () =>
      import(
        /* webpackChunkName: "product-observation" */ '@/views/pages/product-observation/ProductObservationPage.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-product-observation',
    name: 'new-product-observation',
    component: () =>
      import(
        /* webpackChunkName: "new-product-observation" */ '@/views/pages/product-observation/new/Main.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/manage-observation-price/:idProductObservation',
    name: 'manage-observation-price',
    component: () =>
      import(
        /* webpackChunkName: "manage-observation-price" */ '@/views/pages/product-observation/ManageObservationPrice.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/edit-product-observation/:idProductObservation',
    name: 'edit-product-observation',
    component: () =>
      import(
        /* webpackChunkName: "edit-product-observation" */ '@/views/pages/product-observation/new/Main.vue'
      ),
    props: true,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/price-table',
    name: 'price-table',
    component: () =>
      import(
        /* webpackChunkName: "price-table" */ '@/views/pages/price-table/PriceTablePage.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/price-table-publish',
    name: 'price-table-publish',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "price-table-publish" */ '@/views/pages/price-table/PriceTablePublishPage.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/manage-price-table',
    name: 'manage-price-table',
    component: () => import(/* webpackChunkName: "manage-price-table" */ '@/views/pages/price-table/ManageTablePrice.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-product-group',
    name: 'new-product-group',
    component: () => import(/* webpackChunkName: "new-product-group" */ '@/views/pages/product-group/NewProductGroup.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/product-group',
    name: 'product-group',
    component: () => import(/* webpackChunkName: "product-group" */ '@/views/pages/product-group/ProductGroup.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/product-group/:idProductGroup(\\d+)',
    name: 'edit-product-group',
    component: () => import(/* webpackChunkName: "edit-product-group" */ '@/views/pages/product-group/NewProductGroup.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/fraction-table',
    name: 'fraction-table',
    component: () => import(/* webpackChunkName: "fraction-table" */ '@/views/pages/fraction-table/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },

  // GESTÃO DE PDV (pos-management)
  {
    path: '/sales/:company?',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/pages/sales/SalesPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/sales/:company?/:catalog_id',
    name: 'sales-detail',
    component: () => import(/* webpackChunkName: "sales-detail" */ '@/views/pages/sales/SalesDetailPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/subcatalog/:company/:catalog_id/:subcatalogId',
    name: 'subcatalog-products',
    component: () => import(/* webpackChunkName: "subcatalog-products" */ '@/views/pages/sales/SubCatalogDetailPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/subcatalog/:company/:catalog_id/:subcatalogId/:type',
    name: 'subcatalog-services',
    component: () => import(/* webpackChunkName: "subcatalog-products" */ '@/views/pages/sales/SubCatalogServiceDetailPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "catalog" */ '@/views/pages/catalog/ProductCatalogPage.vue'),
    meta: {
      authRequired: true
    }
  },
  // EMPRESAS (company)
  {
    path: '/employees',
    name: 'employees',
    component: () => import(/* webpackChunkName: "employees" */ '@/views/pages/company/employees/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/edit-employee/:idEmployee(\\d+)',
    name: 'edit-employee',
    component: () =>
      import(/* webpackChunkName: "edit-employee" */ '@/views/pages/company/employees/FormEmployeesPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-employees',
    name: 'new-employees',
    component: () =>
      import(/* webpackChunkName: "new-employees" */ '@/views/pages/company/employees/FormEmployeesPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-profile/:id?',
    name: '/new-profile',
    component: () =>
      import(/* webpackChunkName: "new-profile" */ '@/views/pages/company/employees/profile/FormProfile.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/reversal-reason',
    name: 'reversal-reason',
    component: () =>
      import(/* webpackChunkName: "reversal-reason" */ '@/views/pages/company/reversal-reason/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/service/:id?',
    name: 'service',
    component: () =>
      import(/* webpackChunkName: "reversal-reason" */ '@/views/pages/configurations/services/IndexPage.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/discounts-surcharges',
    name: 'discounts-surcharges',
    component: () =>
      import('@/views/pages/company/discounts-surcharges/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () =>
      import(/* webpackChunkName: "payment-methods" */ '@/views/pages/company/payment-methods/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/entry-category',
    name: 'entry-category',
    component: () =>
      import(/* webpackChunkName: "entry-category" */ '@/views/pages/company/entry-category/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  // estoque
  {
    path: '/product-involved',
    name: 'product-involved',
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ '@/views/pages/stock/product-involved/IndexPage.vue'
        ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/stock-recipes',
    name: 'stock-recipes',
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ '@/views/pages/configurations/equipment/EquipmentPage.vue'
        ),
    meta: {
      authRequired: true
    }
  },
  // CONFIGURATIONS
  {
    path: '/equipment',
    name: 'equipment',
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ '@/views/pages/configurations/equipment/EquipmentPage.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/tax-rules/:id?',
    name: 'tax-rules',
    component: () =>
      import(/* webpackChunkName: "tax-rules" */ '@/views/pages/configurations/tax-rules/TaxRules.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/category-icms/:id?',
    name: 'category-icms',
    component: () =>
      import(/* webpackChunkName: "tax-rules" */ '@/views/pages/configurations/category-icms/CategoryIcms.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/category-pis-cofins/:id?',
    name: 'category-pis-cofins',
    component: () =>
      import(/* webpackChunkName: "tax-rules" */ '@/views/pages/configurations/category-pis-cofins/CategoryPisCofins.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/advanced-configurations',
    name: 'advanced-configurations',
    component: () =>
      import(/* webpackChunkName: "advanced-configurations" */ '@/views/pages/configurations/advanced/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  // CRM
  {
    path: '/blocked-clients/:id?',
    name: 'blocked-clients',
    props: true,
    component: () => import(/* webpackChunkName: "exchange" */ '@/views/pages/crm/blocked-clients/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/blocked-clients-reasson/:id?',
    name: 'blocked-clients-reasson',
    props: true,
    component: () => import(/* webpackChunkName: "exchange" */ '@/views/pages/crm/blocked-clients/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/exchange/:id?',
    name: 'exchange',
    props: true,
    component: () => import(/* webpackChunkName: "exchange" */ '@/views/pages/crm/exchange/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/registered-customers/:id?',
    name: 'registered-customers',
    component: () =>
      import(/* webpackChunkName: "registered-customers" */ '@/views/pages/crm/registered-customers/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/manage-lists',
    name: 'manage-lists',
    component: () => import(/* webpackChunkName: "manage-lists" */ '@/views/pages/crm/manage-lists/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/manage-schedule',
    name: 'manage-schedule',
    component: () => import(/* webpackChunkName: "manage-schedule" */ '@/views/pages/crm/manage-schedule/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/calendar/:id/:company',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "manage-schedule" */ '@/views/pages/crm/manage-schedule/CalendarPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/table-page/:listaId/:id/:company',
    name: 'table-page',
    component: () => import(/* webpackChunkName: "table-page" */ '@/views/pages/crm/manage-lists/TablePage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/list-guests/:listaId/:id/:company/:idChild/:nameList',
    name: 'list-guests',
    component: () =>
      import(/* webpackChunkName: "list-guests" */ '@/views/pages/crm/manage-lists/manage-guests/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/import-products',
    name: 'import-products',
    component: () => import(/* webpackChunkName: "import-products" */ '@/views/pages/products/ImportProducts.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/import-products-result',
    name: 'import-import-products-result',
    component: () => import(/* webpackChunkName: "import-products-result" */ '@/views/pages/products/ImportProductsResult.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/send-file/:typeFile',
    name: 'send-file',
    props: true,
    component: () => import(/* webpackChunkName: "send-file" */ '@/views/pages/products/SendFile.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/import-products-item/:id',
    name: 'import-products-item',
    component: () => import(/* webpackChunkName: "import-products-item" */ '@/views/pages/products/ImportProductsItem.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/direct-print',
    name: 'direct-print',
    component: () =>
      import(/* webpackChunkName: "direct-print" */ '@/views/pages/configurations/direct-print/IndexPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/new-direct-print',
    name: 'new-direct-print',
    component: () =>
      import(/* webpackChunkName: "new-direct-print" */ '@/views/pages/configurations/direct-print/FormPage.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/edit-direct-print/:idDirectPrint(\\d+)',
    name: 'edit-direct-print',
    component: () =>
      import(
        /* webpackChunkName: "edit-direct-print" */ '@/views/pages/configurations/direct-print/FormPage.vue'
      ),
    props: true,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () =>
      import(
        /* webpackChunkName: "chat" */ '@/views/pages/chat/IndexPage.vue'
      ),
    props: true,
    meta: {
      authRequired: true
    }
  },
  {
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/pages/auth/LoginPage.vue'
      )
  }
];

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
});


router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    let loggedIn = sessionStorage.getItem('logged');
    if (!loggedIn) {
      next('/auth');
    }
    return next();
  }
  next();
});

export default router;
